.container {
  width: 1400px;
  margin: auto;
}

.inner-container {
  padding-top: 20px;
  margin-top: 0px;
} 

.card_outer {
  width: 1000px;
  margin: auto;
  background-color: #0d1321;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 30px;
}

.card_main {
  width: 80%;
  margin: 0 auto;
  background-color: #030918;
  border: 1px solid #252932;
  border-radius: 30px;
  padding: 35px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.card_first_div {
  width: 30%;
}

.card_first_div_heading {
  text-align: center;
  color: #2cb60a;
  margin-bottom: 10px;
}

.card_first_div_image_div {
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.swiper_container {
  width: 100% !important;
  height: 100% !important;
}

.swiper-slide-thumb-active {
  opacity: 0.6;
}

.swiper-pagination-bullet {
  width: 7px !important;
  height: 7px !important;
  opacity: 1 !important;
  background: rgb(202, 202, 202) !important;
}

.swiper-pagination-bullets {
  bottom: 0 !important;
  padding-top: 10px !important;
  background: linear-gradient(180deg, transparent, black);
}

.swiper-pagination-bullet-active {
  background-color: #ffa800 !important;
}

.card_first_div_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card_second_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_second_div p {
  font-size: 30px;
  font-weight: 700;
  color: #ffa800;
  display: flex;
  align-items: center;
  gap: 10px
}

.card_second_div p span {
  font-size: 90px;
  font-weight: 800;
}

.card_third_div {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.card_third_div > div {
  padding: 15px 10px;
}

.card_third_div_first {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #06bc4661;
}

.card_third_div_first p {
  font-size: 20px;
  /* color: white; */
}

.card_third_div_first input {
  width: 50px;
  /* background-color: white; */
  color: black;
}

.card_third_div_second {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid #25293261;
}

.card_third_div_third button {
  padding: 10px 0;
  width: 150px;
  font-size: 14px;
  /* color: white; */
  border-radius: 10px;
  border: 2px solid #08ab0d;
  /* Hover styles */
      &:hover {
          background-color: #079542;
          color: white;
      }
}


.sizes_main{
  display: flex;
  align-items: center;
  gap: 15px;
}


@media (max-width:1400px) {
  .container {
      width: 90%;
      margin: auto;
  }
}

@media (max-width:1100px) {
  .card_outer {
      width: 100%;
  }
}


@media (max-width:900px) {
  .card_main {
      flex-direction: column;
      gap: 20px;
      padding: 15px;
      width: 95%;
  }

  .card_first_div {
      width: 220px;
  }

  .card_third_div {
      width: 100%;
  }

  .card_third_div > div {
      padding: 15px 0px;
  }
}